import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useUserSettings } from '@context/session'

import cn from 'classnames'
import s from './SalerInformation.module.css'

export interface SalerInformationProps {
  stocks: Stocks
  selectedSize: string | undefined
  layoutLite?: boolean
}

const SalerInformation: FC<SalerInformationProps> = ({
  stocks,
  selectedSize,
  layoutLite = false,
}) => {
  const { t } = useTranslation()
  const { roles } = useUserSettings()

  const hasTelesales = roles?.includes('ROLE_ALLOWED_TO_SWITCH')
  const hasSaler = roles?.includes('ROLE_SALER')
  const hasPreviousAdmin = roles?.includes('ROLE_PREVIOUS_ADMIN')

  if (!selectedSize || (!hasTelesales && !hasSaler && !hasPreviousAdmin))
    return null

  return (
    <div className={cn(s.container, { [s.lite]: layoutLite })}>
      <p>
        <strong>SKU:</strong> {selectedSize}
      </p>
      {stocks && (hasSaler || hasPreviousAdmin) && (
        <>
          {stocks?.declinations?.[selectedSize]?.availableQuantity !==
            undefined && (
            <p>
              <strong>{t('product:saler.availableQuantity')}</strong>{' '}
              {stocks.declinations[selectedSize].availableQuantity}
            </p>
          )}
          {stocks?.declinations?.[selectedSize]?.virtualQuantity !==
            undefined && (
            <p>
              <strong>{t('product:saler.virtualQuantity')}</strong>{' '}
              {stocks.declinations[selectedSize].virtualQuantity}
            </p>
          )}
          {stocks?.declinations?.[selectedSize]?.warehouseQuantity !==
            undefined && (
            <p>
              <strong>{t('product:saler.warehouseQuantity')}</strong>{' '}
              {stocks.declinations[selectedSize].warehouseQuantity}
            </p>
          )}
        </>
      )}
    </div>
  )
}

export default SalerInformation
