import React, {
  FC,
  useState,
  useRef,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'

import useScreen from '@lib/hooks/useScreen'
import { ArrowRight } from '@components/icons'

import cn from 'classnames'
import s from './ProductGreenableMenu.module.css'

export interface ProductGreenableMenuProps {
  greenablePictograms: Array<{
    code: string
    name: string
    description: string
    path: string
  }>
  selectedPicto: number
  setSelectedPicto: Dispatch<SetStateAction<number>>
}

const ProductGreenableMenu: FC<ProductGreenableMenuProps> = ({
  greenablePictograms,
  selectedPicto,
  setSelectedPicto,
}) => {
  const screen = useScreen()
  const [translate, setTranslate] = useState<number>(0)
  const pictoRef = useRef<Array<HTMLButtonElement>>(new Array())
  const pictoWidth = useRef<number>(screen === 'xs' || screen === 'sm' ? 8 : 4)
  const prevTranslate = useRef<number>(0)
  const sliderRef = useRef<HTMLDivElement>()
  const [carrousel, setCarrousel] = useState<boolean>(false)
  const [morePicto, setMorePicto] = useState<number>(0)

  useEffect(() => {
    const checkCaroussel = () => {
      const sliderWidth = sliderRef?.current?.clientWidth
      const pictosWidth = greenablePictograms.length * pictoWidth.current

      if (sliderWidth < pictosWidth) {
        setCarrousel(true)
        setMorePicto(
          Math.round((pictosWidth - sliderWidth) / pictoWidth.current) + 1
        )
      } else {
        setCarrousel(false)
      }
    }

    checkCaroussel()
    window.addEventListener('resize', checkCaroussel)

    return () => window.removeEventListener('resize', checkCaroussel)
  }, [sliderRef])

  useEffect(() => {
    prevTranslate.current = translate
  }, [translate])

  useEffect(() => {
    for (let i = 0; i < greenablePictograms.length; i++) {
      pictoRef.current[i].style.transform = 'translateX(0px)'
    }
  }, [pictoWidth.current])

  useEffect(() => {
    pictoWidth.current = 42 + (screen === 'xs' || screen === 'sm' ? 8 : 4)
  }, [screen])

  const getAnimation = (
    index: number,
    translate: number,
    pictoLength: number
  ): { transition?: string; transform?: string } => {
    try {
      if (pictoRef?.current && sliderRef?.current && carrousel) {
        const style = pictoRef.current[index].style.transform

        if (!style || style === '') throw Error

        if (translate <= prevTranslate.current) return { transform: style }

        const currentTranslate = style.match(/\((.*)\p/)

        if (currentTranslate.length < 1) return { transform: style }

        const left =
          pictoRef.current[index].getBoundingClientRect().left -
          sliderRef.current.getBoundingClientRect().left

        return {
          transform: `translateX(${
            left >= 0
              ? parseInt(currentTranslate[1]) - pictoWidth.current
              : parseInt(currentTranslate[1]) +
                (pictoLength - 1) * pictoWidth.current
          }px)`,
          transition:
            left >= 0
              ? 'transform 0.2s ease-in-out'
              : 'transform 0s ease-in-out',
        }
      }
      throw Error
    } catch (e) {
      return {
        transform: `translateX(0px)`,
        transition: 'transform 0.2s ease-in-out',
      }
    }
  }

  return (
    <div className={s.container}>
      <div className={s.slider} ref={sliderRef}>
        {greenablePictograms.map((picto, i) => (
          <button
            key={`pictoContainer-${i}`}
            className={cn(s.pictoContainer, {
              [s.selected]: selectedPicto === i,
            })}
            style={getAnimation(i, translate, greenablePictograms.length)}
            ref={(element) => {
              element ? pictoRef.current.push(element) : null
            }}
            aria-label={picto.name}
            onClick={() => setSelectedPicto(i)}
          >
            <img
              className={s.picto}
              src={`${process.env.NEXT_PUBLIC_ASSETS_URL}${picto.path}`}
              alt={picto.name}
              aria-hidden
            />
          </button>
        ))}
        {carrousel ? (
          <button
            className={s.next}
            onClick={() => setTranslate(translate + 1)}
            arria-hidden
          >
            <span className={s.morePicto}>+{morePicto}</span>
            <ArrowRight className={s.arrowRight} />
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default ProductGreenableMenu
