import { FC } from 'react'
import { NextSeo } from 'next-seo'

import { TagProduct } from '@components/common/Eulerian'
import { TagPageProduct } from '@components/common/GoogleTagManager'
import {
  BreadcrumbStructuredData,
  ProductStructuredData,
} from '@components/common/StructuredData'

import { getCustomMetaDescription, getCustomMetaTitle } from '@utils/meta'

export interface ProductSEOProps {
  product: Product
  productName: string
  currencyCode?: string
  color?: string
  lowestPrice?: string
  prices: Prices
  hasStocks: boolean
  locale: string
  siblings?: Sibling[]
}

const ProductView: FC<ProductSEOProps> = ({
  product,
  productName,
  currencyCode,
  color,
  lowestPrice,
  prices,
  hasStocks,
  locale,
  siblings,
}) => {
  const customMetaTitle = getCustomMetaTitle({ product }) || product.metaTitle
  const customMetaDescription =
    getCustomMetaDescription({ locale, product }) || product.metaDescription
  const priceToDisplay = lowestPrice
    ? prices[lowestPrice].baseValue || prices[lowestPrice].value
    : null

  return (
    <>
      <NextSeo
        title={customMetaTitle}
        description={customMetaDescription}
        noindex={process.env.NEXT_PUBLIC_ENABLE_SEO !== 'enable'}
        nofollow={process.env.NEXT_PUBLIC_ENABLE_SEO !== 'enable'}
        canonical={product.canonicalUrl}
        languageAlternates={product.alternatesUrl}
        openGraph={{
          type: 'website',
          title: customMetaTitle,
          description: customMetaDescription,
          url: product.canonicalUrl,
          images: [
            {
              url: product.medias[0]?.['thickbox'],
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          cardType: 'summary',
          site: '@smallable',
        }}
      />
      <ProductStructuredData
        product={product}
        productName={productName}
        price={priceToDisplay}
        currency={currencyCode}
        availability={hasStocks}
      />
      <BreadcrumbStructuredData
        breadCrumbItems={product.breadcrumb}
        productName={productName}
      />
      <TagProduct
        name={product.name}
        lowestPrice={lowestPrice}
        hasStocks={hasStocks}
        productId={product.id}
        prices={product.prices}
        stocks={product.stocks}
        brand={product.brand.name}
        color={color}
        size={product.declinations[0]?.sizeListing}
        image={product.medias[0]?.['thickbox']}
        reference={product.reference}
        sections={product.sections?.length ? product.sections[0] : undefined}
        genre={product.persons?.length ? product.persons[0] : undefined}
        sexs={product.sexs?.length ? product.sexs[0] : undefined}
        typologies={product.typologies}
        breadcrumb={product.breadcrumb}
      />
      <TagPageProduct
        name={product.name}
        productId={product.id}
        price={lowestPrice && prices[lowestPrice].value}
        brand={product.brand.name}
        color={color}
        category={product.sections?.length ? product.sections[0] : ''}
      />
    </>
  )
}

export default ProductView
