export const formatUrlNext = (url: string) => {
  let urlFomatted = url?.endsWith('/') ? url.slice(0, -1) : url
  return urlFomatted
}

export const removeLocale = ({ path, locale }) => {
  if (locale === 'catchAll') return path
  if (path.startsWith(`/${locale}/`)) return path.replace(`/${locale}/`, '/')
  return path
}

export const transformUrlClientToServer = (url: string, locale: string) => {
  const nextDataReg = /(_next\/data\/[^/]+\/)|(\.json)|(&?pages=[^&]*)/g
  let transfornmedUrl = url.replace(nextDataReg, '')
  if (locale !== 'catchAll' && !transfornmedUrl.startsWith(`/${locale}/`)) {
    transfornmedUrl = `/${locale}${transfornmedUrl}`
  }
  return transfornmedUrl.endsWith('?')
    ? transfornmedUrl.slice(0, -1)
    : transfornmedUrl
}
