import React, { FC, useEffect, useState } from 'react'
import { useUserSettings } from '@context/session'
import NextHead from 'next/head'
import Script from 'next/script'
import s from './AlmaBanner.module.css'
import useTranslation from 'next-translate/useTranslation'
import useScreen, { Screen } from '@lib/hooks/useScreen'

declare global {
  interface Window {
    Alma: any
  }
}
interface AlmaBannerProps {
  amount: number
  locale: string
  className?: string
}

const ALMA_MERCHANT_ID = process.env.NEXT_PUBLIC_ALMA_MERCHANT_ID

const AlmaBanner: FC<AlmaBannerProps> = ({ amount, locale, className }) => {
  const { deliveryCountryCode, currencyCode } = useUserSettings()
  const screen = useScreen()
  const [isAlmaLoaded, setIsAlmaLoaded] = useState<boolean>(false)
  const [showAlmaCondition, setShowAlmaCondition] = useState<boolean>(false)
  const [almaConditionText, setAlmaConditionText] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!isAlmaLoaded || deliveryCountryCode !== 'FR' || currencyCode !== 'EUR')
      return null
    let widgets = window?.Alma?.Widgets?.initialize(
      ALMA_MERCHANT_ID,
      window?.Alma?.ApiMode?.LIVE
    )
    widgets?.add(window?.Alma?.Widgets?.PaymentPlans, {
      container: '#alma-widget',
      purchaseAmount: amount * 100,
      locale: locale,
      hideIfNotEligible: true,
      transitionDelay: 5500,
      monochrome: true,
      hideBorder: true,
      plans: [
        {
          installmentsCount: 3,
          minAmount: 20000,
          maxAmount: 200000,
        },
      ],
    })
  }, [isAlmaLoaded, deliveryCountryCode, currencyCode])

  useEffect(() => {
    if (!isAlmaLoaded && typeof window?.Alma !== 'undefined')
      setIsAlmaLoaded(true)
  }, [])

  useEffect(() => {
    if (amount < 200) setShowAlmaCondition(true)
  }, [amount])

  useEffect(() => {
    setAlmaConditionText(
      [Screen.xs, Screen.sm].includes(screen)
        ? t('product:almaConditionMobile')
        : t('product:almaCondition')
    )
  }, [screen])

  if (deliveryCountryCode !== 'FR' || currencyCode !== 'EUR') return null

  return (
    <div className={className}>
      <NextHead>
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/@alma/widgets@3.x/dist/widgets.min.css"
        />
      </NextHead>
      <Script
        src="https://cdn.jsdelivr.net/npm/@alma/widgets@3.x.x/dist/widgets.umd.js"
        strategy="lazyOnload"
        onLoad={() => {
          setIsAlmaLoaded(true)
        }}
      />
      <div id="alma-widget" />
      {showAlmaCondition && (
        <div className={s.almaWrapperWording}>
          <div>
            <p className={s.almaWording}>{almaConditionText}</p>
          </div>
          <img className={s.almaLogo} src="/img/alma.png" alt="alma" />
        </div>
      )}
    </div>
  )
}

export default AlmaBanner
