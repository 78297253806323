import { FC, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { Button } from '@components/ui'
import { Mail } from '@components/icons'

import cn from 'classnames'
import s from './StickyBottomLine.module.css'

export interface StickyBottomLineProps {
  className?: string
  hasStocks?: boolean
  price: DeclinationPrice
  labelFrom?: boolean
  productUnavailable?: boolean
  onAddToBasket?: () => void
  onOpenNotifyMeModal: () => void
}

const StickyBottomLine: FC<StickyBottomLineProps> = ({
  className,
  hasStocks,
  price,
  labelFrom,
  productUnavailable = false,
  onAddToBasket = () => {},
  onOpenNotifyMeModal = () => {},
}) => {
  const { t } = useTranslation()
  const { amount, baseAmount, discount } = price

  const IconComponent = useCallback(
    () => <Mail className={s.notifyMeIcon} />,
    []
  )

  return (
    <div className={cn(s.root, className)}>
      <div className={cn(s.priceLine, 'notranslate')}>
        <span>
          {labelFrom && (
            <span className={s.labelFrom}>{t('product:price.from')}&nbsp;</span>
          )}
          <span
            className={
              baseAmount && baseAmount !== amount ? s.basePrice : s.price
            }
          >
            {amount}
          </span>
        </span>
        {baseAmount && baseAmount !== amount && (
          <div className={s.discountLine}>
            <span className={s.price}>{baseAmount}</span>
            <span className={s.discount}>{`-${discount}`}</span>
          </div>
        )}
      </div>
      {hasStocks ? (
        <Button
          className={cn(s.button, { [s.unavailable]: productUnavailable })}
          onClick={onAddToBasket}
        >
          {t('product:add')}
        </Button>
      ) : productUnavailable ? (
        <Button className={cn(s.button, s.unavailable)}>
          {t('product:isUnavailable')}
        </Button>
      ) : (
        <Button
          className={s.button}
          onClick={onOpenNotifyMeModal}
          icon={IconComponent}
        >
          {t('product:notification')}
        </Button>
      )}
    </div>
  )
}

export default StickyBottomLine
