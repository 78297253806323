const brandUrl = ({
  brand,
  locale,
  pathname,
}: {
  brand: ProductBrand
  locale: string
  pathname: string
}) =>
  `${locale ? `/${locale}` : ''}${pathname ? `/${pathname}` : ''}/${brand.slug}`

export default brandUrl
