import React, { FC } from 'react'

import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { getStore } from '@utils/countries'
import AlmaBanner from '@components/common/AlmaBanner'
import KlarnaPlacement from '@components/common/KlarnaPlacement'

import { PayPalMessages, PayPalScriptProvider } from '@paypal/react-paypal-js'

import s from './ProductPaymentsMethods.module.css'

export interface ProductPaymentsMethodsProps {
  amount: number
  euroAmount?: number
  className?: string
}

const setPaypalBuyerCountry = (locale, deliveryCountryCode, currencyCode) => {
  if (
    locale === 'fr' &&
    currencyCode === 'EUR' &&
    deliveryCountryCode === 'FR'
  ) {
    return 'FR'
  }

  if (
    locale === 'es' &&
    currencyCode === 'EUR' &&
    deliveryCountryCode === 'ES'
  ) {
    return 'ES'
  }

  if (
    locale === 'it' &&
    currencyCode === 'EUR' &&
    deliveryCountryCode === 'IT'
  ) {
    return 'IT'
  }

  if (
    locale === 'de' &&
    currencyCode === 'EUR' &&
    deliveryCountryCode === 'DE'
  ) {
    return 'DE'
  }

  if (
    locale === 'en' &&
    currencyCode === 'GBP' &&
    deliveryCountryCode === 'GB'
  ) {
    return 'GB'
  }

  if (
    locale === 'en' &&
    currencyCode === 'USD' &&
    deliveryCountryCode === 'US'
  ) {
    return 'US'
  }

  return 'UNKNOWN'
}

const ProductPaymentsMethods: FC<ProductPaymentsMethodsProps> = ({
  className,
  amount,
  euroAmount,
}) => {
  const { locale } = useRouter()

  const screen = useScreen()

  const { deliveryCountryCode, currencyCode } = useUserSettings()

  const store = getStore({ locale, deliveryCountryCode })

  const PAYPAL_APIKEY = process.env.NEXT_PUBLIC_PAYPAL_APIKEY || ''

  const paypalBuyerCountry = setPaypalBuyerCountry(
    locale,
    deliveryCountryCode,
    currencyCode
  )

  const stylePaypal = {
    layout: 'text',
    logo: {
      type: 'primary',
    },
    text: {
      align: 'left',
      size: '14',
    },
  }

  return (
    <div className={className}>
      {deliveryCountryCode !== 'FR' && euroAmount >= 200 && (
        <KlarnaPlacement
          className={s.klarnaPlacement}
          placementValue={{
            key: 'credit-promotion-badge',
            locale: store,
            purchaseAmount: amount ? (amount * 100).toString() : '',
          }}
        />
      )}
      {[Screen.xs, Screen.sm].includes(screen) && (
        <AlmaBanner
          amount={amount}
          locale={locale}
          className={s.almaPlacement}
        />
      )}
      {PAYPAL_APIKEY?.length && paypalBuyerCountry !== 'UNKNOWN' ? (
        <PayPalScriptProvider
          options={{
            'client-id': PAYPAL_APIKEY,
            components: 'messages',
            'data-pp-buyercountry': paypalBuyerCountry,
          }}
        >
          <PayPalMessages
            className={s.paypalDisclaimer}
            style={stylePaypal as any}
            forceReRender={[stylePaypal]}
            amount={amount}
          />
        </PayPalScriptProvider>
      ) : null}
    </div>
  )
}

export default ProductPaymentsMethods
