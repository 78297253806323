import { FC } from 'react'

import BreadCrumb from '@components/product/BreadCrumb'
import ProductDetails from '@components/product/ProductDetails'
import { ProductMedias } from '@components/product'

import s from './ProductPresentation.module.css'

export interface ProductPresentationProps {
  product: Product
  setProduct: React.Dispatch<React.SetStateAction<Product>>
  productName: string
  prices: Prices
  lowestPrice?: string
  hasAttachColor: boolean
}

const ProductPresentation: FC<ProductPresentationProps> = ({
  product,
  setProduct,
  productName,
  prices,
  lowestPrice,
  hasAttachColor,
}) => (
  <section className={s.topContainer}>
    <BreadCrumb
      paths={product?.breadcrumb}
      displayLastItemOnMobile
      productName={productName}
    />
    {productName && product?.medias?.length ? (
      <ProductMedias
        productMedias={product.medias}
        productName={productName}
        productVideo={product.urlVideo}
      />
    ) : null}
    {productName && prices ? (
      <ProductDetails
        product={product}
        setProduct={setProduct}
        productName={productName}
        prices={prices}
        lowestPrice={lowestPrice}
        hasAttachColor={hasAttachColor}
      />
    ) : null}
  </section>
)

export default ProductPresentation
