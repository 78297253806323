import React, { FC, useEffect, useState } from 'react'
import NextHead from 'next/head'
import { useRouter } from 'next/router'

import s from './ShipupTracking.module.css'

interface ShipupTrackingProps {
  trackingId?: string
}

const SHIPUP_API_KEY = process.env.NEXT_PUBLIC_SHIPUP_API_KEY

const ShipupTracking: FC<ShipupTrackingProps> = ({ trackingId }) => {
  const { locale } = useRouter()

  const [isRender, setIsRender] = useState<boolean>(false)

  if (!trackingId?.length || !SHIPUP_API_KEY?.length) return null

  const shipupConfig = `
  (function() {
    let shipup = new ShipupJS.default('${SHIPUP_API_KEY}');
    let element = document.getElementById('shipup-container');
    let arguments = {
      language: '${locale}',
      trackingNumber: '${trackingId}',
      searchEnabled: false
    };
    shipup.render(element,arguments);
  })();
`

  useEffect(() => {
    setIsRender(true)
  }, [])

  return (
    <>
      <NextHead>
        <script
          type="text/javascript"
          src="https://cdn.shipup.co/latest_v2/shipup-js.js"
        />
        <link
          rel="stylesheet"
          href="https://cdn.shipup.co/latest_v2/shipup.css"
        />
        {isRender && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: shipupConfig }}
            defer
          />
        )}
      </NextHead>
      <div className={s.container}>
        <div id="shipup-container" className={s.content} />
      </div>
    </>
  )
}

export default ShipupTracking
