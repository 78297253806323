import React, { FC } from 'react'

import { useUserSettings } from '@context/session'
import {
  klarnaPlacement,
  useKlarnaOnSiteMessaging,
  useKlarnaPlacementRefresh,
} from '@frontend-sdk/klarna'

interface PlacementValue {
  key: string
  locale: string
  inline?: boolean
  preloaded?: boolean
  purchaseAmount?: string
  theme?: string
}

interface KlarnaPlacementProps {
  className?: string
  placementValue: PlacementValue
}

const KlarnaPlacement: FC<KlarnaPlacementProps> = ({
  className,
  placementValue,
}) => {
  const { deliveryCountryCode } = useUserSettings()

  const northAmerica = [
    'AI',
    'AG',
    'CW',
    'SX',
    'AW',
    'BS',
    'BB',
    'BZ',
    'BM',
    'CA',
    'CR',
    'DM',
    'SV',
    'US',
    'GL',
    'GD',
    'GP',
    'GT',
    'HT',
    'HN',
    'KY',
    'VI',
    'JM',
    'MQ',
    'MX',
    'MS',
    'NI',
    'PA',
    'DO',
    'BL',
    'KN',
    'LC',
    'MF',
    'VC',
    'TT',
  ]

  const isNA: boolean = northAmerica.includes(deliveryCountryCode)

  const onSiteMessagingValue = {
    clientId: isNA
      ? '4ab441e2-2279-5a4c-bb00-49672bdc0e3f'
      : 'c19713d7-52ac-5841-b59c-29a7737cf4ca',
    region: isNA ? 'na' : 'eu',
  }

  useKlarnaOnSiteMessaging(onSiteMessagingValue as any)
  useKlarnaPlacementRefresh()
  return (
    <div className={className}>{klarnaPlacement(placementValue as any)}</div>
  )
}

export default KlarnaPlacement
