import { FC } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Container } from '@components/ui'
import { TagGeneric } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'
import { NewsletterModalView } from '@components/common/NewsletterModal/NewsletterModal'

import s from './NewsletterView.module.css'

interface Props {
  countries: Array<Country>
}

const NewsletterView: FC<Props> = ({ countries }) => {
  const { t } = useTranslation()
  const { locale } = useRouter()

  const metaTitle = 'Newsletter Smallable'
  const metaDescription = t('layout:common.metaDescription')
  const canonicalUrl = `https://www.smallable.com/${locale}/newsletter-subscription`
  const alternateUrl = [
    {
      hrefLang: 'de',
      href: 'https://www.smallable.com/de/newsletter-subscription',
    },
    {
      hrefLang: 'en',
      href: 'https://www.smallable.com/en/newsletter-subscription',
    },
    {
      hrefLang: 'es',
      href: 'https://www.smallable.com/es/newsletter-subscription',
    },
    {
      hrefLang: 'fr',
      href: 'https://www.smallable.com/fr/newsletter-subscription',
    },
    {
      hrefLang: 'it',
      href: 'https://www.smallable.com/it/newsletter-subscription',
    },
  ]

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        noindex
        nofollow
        canonical={canonicalUrl}
        languageAlternates={alternateUrl}
        openGraph={{
          type: 'website',
          title: metaTitle,
          description: metaDescription,
          url: canonicalUrl,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_ASSETS_URL}/bundles/smallablefront/css/img/logo.png`,
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          cardType: 'summary',
          site: '@smallable',
        }}
      />
      <TagGeneric urlp="/newsletter-subscription" />
      <TagPageCategory pageCategory="newsletter-subscription" />
      <Container className={s.container}>
        <div className={s.content}>
          <NewsletterModalView countries={countries} layout="page" />
        </div>
      </Container>
    </>
  )
}

export default NewsletterView
