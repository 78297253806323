import { FC, useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'

import ProductActions from '@components/product/ProductDetails/ProductActions'
import PriceBlock from '@components/product/ProductDetails/PriceBlock'
import AddToListButtons from '@components/product/ProductDetails/AddToListButtons'
import SalerInformation from '@components/product/ProductDetails/SalerInformation'
import { LoadingDots } from '@components/ui'
import { ProductTags } from '@components/product'
import type { Notification } from '@components/common/Alert/Alert'
import TrustMultiSource from '@components/common/TrustMulti/TrustMultiSource'
import MediaQueries from '@components/common/MediaQueries'
import { ENABLE_TRUSTPILOT } from '@constants'
import { Link } from 'components/ui'

import brandUrl from '@utils/url/brand'
import { fetchProduct } from '@mw/products'
import { isSinglePrice } from '@utils/prices'
import { productIsUnavailable } from '@utils/stocks'

import cn from 'classnames'
import s from './ProductDetails.module.css'

export interface ProductDetailsProps {
  product: Product
  setProduct: React.Dispatch<React.SetStateAction<Product>>
  productName: string
  prices: Prices
  lowestPrice?: string
  hasAttachColor: boolean
}

const ProductDetails: FC<ProductDetailsProps> = ({
  product,
  setProduct,
  productName,
  prices,
  lowestPrice,
  hasAttachColor,
}) => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const { currencyCode, deliveryCountryCode } = useUserSettings()

  const [notification, setNotification] = useState<Notification | null>(null)
  const [selectedSize, setSelectedSize] = useState<string | undefined>(
    product?.declinations?.length === 1
      ? product.declinations[0]?.sku
      : undefined
  )
  const enableTrustPilot = ENABLE_TRUSTPILOT
  const [isSizeSelectorExpanded, setSizeSelectorExpanded] = useState(false)

  const singlePrice = isSinglePrice(prices)
  const isUnavailable = productIsUnavailable(
    product.stocks,
    product.alertAvailability
  )

  useEffect(() => {
    ;(async () => {
      try {
        if (
          currencyCode !== product?.prices?.currencyCode ||
          deliveryCountryCode !== product?.prices?.countryCode
        ) {
          const apiProduct = await fetchProduct({
            id: product?.id,
            locale: locale as string,
          })
          if (apiProduct?.prices) setProduct(apiProduct)
          else
            throw new Error('No data found for this product on this delivery')
        }
      } catch (err) {
        console.error(err)
        if (window) window.location.reload()
      }
    })()
  }, [currencyCode, deliveryCountryCode])

  const skuForTrustMulti = useMemo(() => {
    let defaultSkus =
      product?.declinations?.map((declination) => declination.sku) || []
    if (
      selectedSize &&
      product?.declinations?.some((d) => d.sku === selectedSize)
    ) {
      return [selectedSize]
    }
    return defaultSkus
  }, [product?.declinations, selectedSize])
  return (
    <div className={cn(s.container, s.isSticky)}>
      <div
        className={cn(s.tagline, {
          ['float-right']: !(
            product?.tags?.isGreenable ||
            product?.tags?.isCharitable ||
            product?.tags?.isNew ||
            product?.tags?.isExclusive
          ),
        })}
      >
        {product?.tags ? (
          <div className={s.tagContainer}>
            <ProductTags
              tags={product?.tags}
              isATwoTagsCard
              layout="productDetails"
            />
          </div>
        ) : null}

        <AddToListButtons
          product={product}
          selectedSize={selectedSize}
          setNotification={setNotification}
          className={cn(s.listButtonsMobile, {
            ['mt-1']: !(
              product?.tags?.isGreenable ||
              product?.tags?.isCharitable ||
              product?.tags?.isNew ||
              product?.tags?.isExclusive
            ),
          })}
          disable={isUnavailable}
        />
      </div>

      <div className={s.titleContainer}>
        <h1 className={s.title}>
          <Link
            next
            href={brandUrl({
              brand: product?.brand,
              locale,
              pathname: t('product:brands'),
            })}
            className="notranslate"
          >
            {product?.brand?.name}
          </Link>
          &nbsp;
          <span className={s.name}>{productName}</span>
        </h1>
      </div>

      <SalerInformation stocks={product?.stocks} selectedSize={selectedSize} />
      {enableTrustPilot && (
        <MediaQueries hidden={['xs', 'sm', 'md']}>
          <TrustMultiSource sku={skuForTrustMulti} />
        </MediaQueries>
      )}
      {prices && lowestPrice ? (
        <PriceBlock
          selectedSize={selectedSize}
          prices={prices}
          lowestPrice={lowestPrice}
          singlePrice={singlePrice}
          className={s.priceBlock}
        />
      ) : (
        <span className={s.loading}>
          <LoadingDots />
        </span>
      )}

      <ProductActions
        product={product}
        prices={prices}
        lowestPrice={lowestPrice}
        singlePrice={singlePrice}
        hasAttachColor={hasAttachColor}
        notification={notification}
        setNotification={setNotification}
        selectedSize={selectedSize}
        setSelectedSize={setSelectedSize}
        isSizeSelectorExpanded={isSizeSelectorExpanded}
        setSizeSelectorExpanded={setSizeSelectorExpanded}
        enableTrustPilot={enableTrustPilot}
      />
    </div>
  )
}

export default ProductDetails
