const TRANSKEYSDESCRIPTION = {
  fr: {
    new: 'Nouveau ! ',
    exclusive: 'Exclusivité Smallable :',
    original: 'Retrouvez sur Smallable :',
    greenable: 'Label Greenable. ',
    seo: 'Découvrez aussi notre sélection ',
  },
  en: {
    new: 'New! ',
    exclusive: 'Exclusive to Smallable:',
    original: 'Shop now on Smallable:',
    greenable: 'Eco-friendly. ',
    seo: 'Plus discover our selection of',
  },
  de: {
    new: 'Jetzt neu! ',
    exclusive: 'Exklusiv bei Smallable:',
    original: 'Neueste Trends bei Smallable:',
    greenable: 'Greenable Label. ',
    seo: 'Entdecken Sie auch unsere Auswahl an',
  },
  it: {
    new: 'Novità! ',
    exclusive: 'Esclusiva Smallable:',
    original: 'Scopri su Smallable:',
    greenable: 'Etichetta Greenable. ',
    seo: 'Scopri anche la nostra selezione di',
  },
  es: {
    new: '¡Novedad! ',
    exclusive: 'Exclusividad Smallable:',
    original: 'Descubre en Smallable:',
    greenable: 'Etiqueta Greenable. ',
    seo: 'Descubre también nuestra selección de',
  },
}

export const getCustomMetaTitle = ({ product }: { product: Product }) => {
  if (!product) return null
  const metaTitle = `${product.brand?.name} - ${product.name} ${
    product.attachColor ? '- ' + product.color : ''
  } | Smallable`
  return metaTitle
}

export const getCustomMetaDescription = ({
  locale,
  product,
}: {
  locale: string
  product: Product
}) => {
  if (!product || !locale) return null
  const { ...keys } = TRANSKEYSDESCRIPTION[locale]
  const introDescription = `${product.tags?.isNew ? keys.new : ''}${
    product.tags?.isExclusive ? keys.exclusive : keys.original
  }`
  const productDescription = `${product.brand?.name} - ${product.name}${
    product.attachColor ? ' - ' + product.color + '.' : '.'
  } ${product.tags?.isGreenable ? keys.greenable : ''}`
  const description = `${introDescription} ${productDescription}`
  const endDescription = `${keys.seo}${
    locale === 'fr'
      ? product.typologies?.[0].match(/^[aieouAIEOU].*/)
        ? "d'"
        : 'de '
      : ' '
  }${product.typologies?.[0]?.replace(/-/g, ' ') || ''}${
    product.thematics?.[0] === 'mode' ? ' ' + product.persons?.[0] : ''
  }.`
  if (description.length + endDescription.length <= 160)
    return description + endDescription
  else return description
}
