import { FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import ProductPresentation from '@components/product/ProductPresentation'
import { Container, Modal } from '@components/ui'
import {
  ProductDesigner,
  ProductInspiration,
  ProductMediaModal,
  ProductsGrid,
  ProductsList,
  ProductsSlider,
  ProductVideoModal,
} from '@components/product'
import ProductSEO from './ProductSEO'

import MediaQueries from '@components/common/MediaQueries'
import { MODALS } from '@constants'
import { getItemsName } from '@utils/basket/items'
import { hasProductStocks } from '@utils/stocks'
import { formattedObjPrices, getLowestPriceDeclination } from '@utils/prices'
import { isMobile, isTablet } from 'react-device-detect'
import ProductGiftCard from '@components/product/ProductGiftCard'

export interface ProductViewProps {
  productData: Product
}

const ProductView: FC<ProductViewProps> = ({ productData }) => {
  const { locale } = useRouter()
  const { currencyCode } = useUserSettings()
  const { modalView, modalViewProps, displayModal, closeModal } = useUI()
  const screen = useScreen()

  const [product, setProduct] = useState(productData)
  const [displaySameTypeProducts, setDisplaySameTypeProducts] = useState(true)
  const [productsPerLine, setProductsPerLine] = useState(
    ![Screen.xs, Screen.sm, Screen.md].includes(screen) ? 1 : 4
  )
  const prices = formattedObjPrices(
    product.prices,
    locale as string,
    productData.prices
  ) as Prices
  const lowestPrice = getLowestPriceDeclination(prices)
  const color = productData?.color
  const hasAttachColor = productData?.attachColor
  const productName = getItemsName({
    attachColor: hasAttachColor,
    name: product.name,
    color: color,
  })
  const siblings = productData?.siblings || []
  const hasStocks = hasProductStocks(product.stocks)
  const mobileDevice =
    isMobile || [Screen.xs, Screen.sm, Screen.md].includes(screen)

  useEffect(() => {
    if (![Screen.xs, Screen.sm, Screen.md].includes(screen))
      setProductsPerLine(4)
    else setProductsPerLine(1)
    setDisplaySameTypeProducts(!isTablet)
  }, [screen, isTablet])

  return (
    <>
      <Container>
        <ProductSEO
          product={product}
          productName={productName}
          currencyCode={currencyCode}
          color={color}
          lowestPrice={lowestPrice}
          prices={prices}
          hasStocks={hasStocks}
          locale={locale}
          siblings={siblings}
        />
        {product.brand?.slug === 'smallable-gift-card' ? (
          <ProductGiftCard
            productName={productName}
            productSku={product?.declinations?.[0]?.sku}
            medias={product.medias?.[0] || null}
          />
        ) : (
          <>
            <ProductPresentation
              product={product}
              setProduct={setProduct}
              productName={productName}
              prices={prices}
              lowestPrice={lowestPrice}
              hasAttachColor={hasAttachColor}
            />

            {product?.designer && (
              <ProductDesigner designer={product.designer} />
            )}

            {product?.similarProducts?.length && displaySameTypeProducts ? (
              <div>
                {product?.similarProducts?.length > 3 ||
                (product?.similarProducts?.length > 1 && mobileDevice) ? (
                  <ProductsSlider
                    products={product.similarProducts}
                    mobileDevice={mobileDevice}
                  />
                ) : (
                  <ProductsList products={product.similarProducts} />
                )}
              </div>
            ) : null}

            {product?.inspirationBlock ? (
              <MediaQueries hidden={['xs', 'sm']} seoReadable={false}>
                <ProductInspiration
                  title={product.inspirationBlock.title}
                  htmlTitle={product.inspirationBlock.htmlTitle}
                  media={product.inspirationBlock.media}
                  url={product.inspirationBlock.url}
                  products={product.inspirationBlock.products}
                />
              </MediaQueries>
            ) : null}

            {product?.sameTypeProducts?.products?.length ? (
              <ProductsGrid
                products={product.sameTypeProducts.products}
                productsTitle={product.sameTypeProducts.title}
                productsTotal={product.sameTypeProducts.total}
                productsParameters={product.sameTypeProducts.parameters}
                chunk={product.sameTypeProducts.parameters?.limit}
                productsPerLine={productsPerLine}
                isPaginate={false}
                categoryUrl={
                  product?.breadcrumb?.[product.breadcrumb.length - 1]?.href ||
                  null
                }
              />
            ) : null}
          </>
        )}
      </Container>
      <Modal
        open={
          displayModal &&
          [MODALS.PRODUCT_MEDIA, MODALS.PRODUCT_VIDEO].includes(modalView)
        }
        onClose={closeModal}
        layout={[MODALS.PRODUCT_VIDEO].includes(modalView) ? 'dark' : 'white'}
      >
        {modalView === MODALS.PRODUCT_MEDIA && (
          <ProductMediaModal
            productName={modalViewProps && modalViewProps.productName}
            productMedias={modalViewProps && modalViewProps.productMedias}
            currentIndexMedia={
              modalViewProps && modalViewProps.currentIndexMedia
            }
          />
        )}
        {modalView === MODALS.PRODUCT_VIDEO && (
          <ProductVideoModal src={modalViewProps && modalViewProps.src} />
        )}
      </Modal>
    </>
  )
}

export default ProductView
