import { FC } from 'react'
import { useUserSettings } from '@context/session'
import { NextSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { Container } from '@components/ui'
import { isMobile } from 'react-device-detect'

import ProductListingLayout from 'components/blocks-cms/ProductListingLayout'

import {
  analyticsTagsAlgolia,
  filterExcludedCountries,
  FROM,
  getAlgoliaIndexName,
  routingConfig,
  searchClient,
  userTokenAlgolia,
} from '@lib/lib-algolia/algolia'
import { Configure, InstantSearch } from 'react-instantsearch'

import s from './SearchView.module.css'

interface SearchViewProps {
  pageParams?: { [key: string]: string }
}

const SearchView: FC<SearchViewProps> = ({ pageParams }) => {
  const { locale, query } = useRouter()
  const { t } = useTranslation()
  const { deliveryCountryCode, uid } = useUserSettings()

  const metaTitle = t('layout:common.title')
  const metaDescription = t('layout:common.metaDescription')
  const canonicalUrl = `https://www.smallable.com/${locale}/search`
  const alternateUrl = [
    {
      hrefLang: 'de',
      href: 'https://www.smallable.com/de/search',
    },
    {
      hrefLang: 'en',
      href: 'https://www.smallable.com/en/search',
    },
    {
      hrefLang: 'es',
      href: 'https://www.smallable.com/es/search',
    },
    {
      hrefLang: 'fr',
      href: 'https://www.smallable.com/fr/search',
    },
    {
      hrefLang: 'it',
      href: 'https://www.smallable.com/it/search',
    },
  ]

  const querySearch = pageParams?.q || null

  const productsPerListing = Number(
    process.env.NEXT_PUBLIC_PRODUCTS_PER_LISTING
  )
  const _sort = Array.isArray(query?._sort) ? query?._sort[0] : query?._sort
  const indexName = getAlgoliaIndexName(
    deliveryCountryCode,
    locale,
    _sort || 'pertinence'
  )

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        noindex
        nofollow
        canonical={canonicalUrl}
        languageAlternates={alternateUrl}
        openGraph={{
          type: 'website',
          title: metaTitle,
          description: metaDescription,
          url: canonicalUrl,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_ASSETS_URL}/bundles/smallablefront/css/img/logo.png`,
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          cardType: 'summary',
          site: '@smallable',
        }}
      />

      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        routing={routingConfig(
          deliveryCountryCode,
          locale,
          _sort || 'pertinence'
        )}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          analyticsTags={analyticsTagsAlgolia({
            deliveryCountryCode,
            locale,
            isMobile,
            uid,
            from: FROM.LISTING_PRODUCT,
          })}
          clickAnalytics
          enablePersonalization={false}
          filters={filterExcludedCountries(deliveryCountryCode)}
          hitsPerPage={productsPerListing}
          userToken={userTokenAlgolia()}
        />

        <Container className={s.container}>
          <ProductListingLayout
            querySearch={querySearch}
            pageParams={pageParams}
          />
        </Container>
      </InstantSearch>
    </>
  )
}

export default SearchView
