import React, { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import Trustpilot from '../Trustpilot'
import s from './TrustMultiSource.module.css'

// TrustBox seems to be the same component
const TrustMultiSource = ({ sku }) => {
  const { locale } = useRouter()

  const trustpilotRef = useRef(null)

  const dataLocale = (locale: string) => {
    switch (locale) {
      case 'fr':
        return 'fr-FR'
      case 'en':
        return 'en-US'
      case 'de':
        return 'de-DE'
      case 'es':
        return 'es-ES'
      case 'it':
        return 'it-IT'
      default:
        return 'en-US'
    }
  }

  useEffect(() => {
    if (sku && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustpilotRef.current, true)
    }
  }, [sku])

  const truspilotUrl = `https://${
    locale === 'en' ? 'uk' : locale
  }.trustpilot.com/review/smallable.com`

  return (
    <>
      <Trustpilot />
      <div className={s.root}>
        <div
          ref={trustpilotRef}
          className="trustpilot-widget"
          data-locale={dataLocale(locale)}
          data-template-id="577258fb31f02306e4e3aaf9"
          data-businessunit-id="4f560fdd0000640005136c1a"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
          data-sku={sku}
          data-no-reviews="collapse"
          data-star-color="#000000"
          data-scroll-to-list="true"
          data-style-alignment="left"
        >
          <a href={truspilotUrl} target="_blank" rel="noopener">
            Trustpilot
          </a>
        </div>
      </div>
    </>
  )
}

export default TrustMultiSource
