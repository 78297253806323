import { FC, useState, useEffect, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useUserSettings } from '@context/session'
import useIfLogged from '@lib/hooks/useIfLogged'
import { tagAlgoliaConvertion } from '@lib/lib-algolia'
import { ACTION, FROM } from '@lib/lib-algolia/algolia'

import { Heart, Gift } from '@components/icons'
import GiftListModal from './GiftListModal'
import { Status, Notification } from '@components/common/Alert/Alert'

import { tagEventList } from '@components/common/Eulerian/EulerianEvents'

import cn from 'classnames'
import s from './AddToListButtons.module.css'

export interface AddToListButtonsProps {
  className?: string
  product: Product
  selectedSize: string | undefined
  setNotification: (notification: Notification | null) => void
  disable?: boolean
  algoliaQueryId?: string
}

const AddToListButtons: FC<AddToListButtonsProps> = ({
  className,
  product,
  selectedSize,
  setNotification,
  disable = false,
  algoliaQueryId,
}) => {
  const { t } = useTranslation()
  const { deliveryCountryCode } = useUserSettings()
  const { locale } = useRouter()
  const sessionData = useUserSettings()
  const { wishlist, removeFromWishlist, addToWishlist } = sessionData

  const [isGiftlistOpen, setIsGiftListOpen] = useState<boolean>(false)

  const isInWishList = wishlist?.products?.includes(product.id)

  useEffect(() => {
    setNotification(null)
  }, [selectedSize])

  const handleWishList = useIfLogged(async () => {
    setNotification(null)

    try {
      if (isInWishList) {
        await removeFromWishlist(product?.id, locale)
      } else {
        await addToWishlist(product?.id, locale)
      }
    } catch {
      setNotification({
        status: Status.Error,
        message: t('product:error.defaultMessage'),
      })
    }
    const wishlistTagPath = isInWishList
      ? '/wishlist/remove/page_product'
      : '/wishlist/add/page_product'
    tagEventList({
      urlp: wishlistTagPath,
      locale,
      sessionData,
      productData: product,
      selectedSize,
    })

    if (!isInWishList) {
      tagAlgoliaConvertion({
        deliveryCountryCode,
        from: FROM.DETAIL_PRODUCT,
        objectId: product.reference,
        locale,
        queryID: algoliaQueryId,
        typeAction: ACTION.ADD_TO_WISHLIST,
      })
    }
  })

  const handleGiftList = useIfLogged(() => {
    setNotification(null)
    setIsGiftListOpen(true)
  })

  const closeModal = useCallback(() => {
    setIsGiftListOpen(false)
  }, [setIsGiftListOpen])

  return (
    <>
      <GiftListModal
        isOpen={isGiftlistOpen}
        selectedSize={selectedSize}
        onClose={closeModal}
        product={product}
        eventName="/giftlist/add/page_product"
      />
      <div className={cn(s.addToListContainer, className)}>
        <button
          onClick={() => !disable && handleWishList()}
          className={cn(s.button, { [s.unavailable]: disable })}
          aria-label={t(
            `product:addToList.${isInWishList ? 'removeFrom' : 'addTo'}WishList`
          )}
        >
          <Heart
            className={s.buttonIcon}
            fill={isInWishList ? '#000000' : 'none'}
            size="1rem"
            aria-hidden
            stroke={disable ? '#8E8E93' : 'black'}
          />
          <span className={s.buttonLabel}>
            {t(
              `product:addToList.${
                isInWishList ? 'removeFrom' : 'addTo'
              }WishList`
            )}
          </span>
        </button>

        <button
          onClick={() => !disable && handleGiftList()}
          className={cn(s.button, { [s.unavailable]: disable })}
          aria-label={t('product:addToList.addToAList')}
        >
          <Gift
            className={s.buttonIcon}
            size="1rem"
            stroke={disable ? '#8E8E93' : 'black'}
            aria-hidden
          />
          <span className={s.buttonLabel}>
            {t('product:addToList.addToAList')}
          </span>
        </button>
      </div>
    </>
  )
}

export default AddToListButtons
