import React, { FC, SetStateAction, Dispatch } from 'react'

import cn from 'classnames'
import s from '../ProductGreenableMenu/ProductGreenableMenu.module.css'

export interface ProductGreenableMenuLightProps {
  greenablePictograms: Array<{
    code: string
    name: string
    description: string
    path: string
  }>
  selectedPicto: number
  setSelectedPicto: Dispatch<SetStateAction<number>>
}

const ProductGreenableMenuLight: FC<ProductGreenableMenuLightProps> = ({
  greenablePictograms,
  selectedPicto,
  setSelectedPicto,
}) => {
  return (
    <div className={s.container}>
      {greenablePictograms.map((picto, i) => (
        <button
          key={`pictoContainer-${i}`}
          className={cn(s.pictoContainer, {
            [s.selected]: selectedPicto === i,
          })}
          aria-label={picto.name}
          onClick={() => setSelectedPicto(i)}
        >
          <img
            className={s.picto}
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}${picto.path}`}
            alt={picto.name}
            aria-hidden
          />
        </button>
      ))}
    </div>
  )
}

export default ProductGreenableMenuLight
