import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'
import useScreen, { Screen } from '@lib/hooks/useScreen'

import { Button, LoadingDots } from '@components/ui'
import { Mail } from '@components/icons'
import { Notification, Status } from '@components/common/Alert/Alert'
import {
  ProductDeliveryInfo,
  ProductDescription,
  ProductSiblingSelector,
  ProductSizeSelector,
  TrustReview,
} from '@components/product'
import AddToListButtons from '../AddToListButtons'
import ProductPaymentsMethods from '../ProductPaymentsMethods'
import StickyBottomLine from '../StickyBottomLine'
import ProductGreenable from '../ProductGreenable'
import Alert from '@components/common/Alert'

import { MODALS, TOOLTIPS } from '@constants'
import {
  hasDeclinationStocks,
  hasProductStocks,
  productIsUnavailable,
} from '@utils/stocks'
import { tagEventAddToBasket } from '@components/common/Eulerian/EulerianEvents'
import { ACTION, FROM, tagAlgoliaConvertion } from '@lib/lib-algolia/algolia'

import s from './ProductActions.module.css'
import cn from 'classnames'
import MediaQueries from '@components/common/MediaQueries/MediaQueries'
import TrustMultiSource from '@components/common/TrustMulti/TrustMultiSource'

export interface ProductActionsProps {
  product: Product
  prices: Prices
  lowestPrice?: string
  singlePrice?: boolean
  hasAttachColor: boolean
  notification: Notification | null
  setNotification: React.Dispatch<React.SetStateAction<Notification | null>>
  selectedSize: string | undefined
  setSelectedSize: React.Dispatch<React.SetStateAction<string | undefined>>
  isSizeSelectorExpanded: boolean
  setSizeSelectorExpanded: Dispatch<SetStateAction<boolean>>
  enableTrustPilot: boolean
}

const SIZE_GUIDE = {
  DEFAULT: 'sizes',
  SHOES: 'shoeSizes',
}

const ProductActions: FC<ProductActionsProps> = ({
  product,
  prices,
  lowestPrice,
  singlePrice,
  hasAttachColor,
  notification,
  setNotification,
  selectedSize,
  setSelectedSize,
  isSizeSelectorExpanded,
  setSizeSelectorExpanded,
  enableTrustPilot,
}) => {
  const { t } = useTranslation()
  const { locale, query } = useRouter()
  const { algsearch } = query
  const algoliaQueryId = Array.isArray(algsearch) ? algsearch[0] : algsearch
  const screen = useScreen()
  const { setModalView, openModal, openTooltip } = useUI()
  const sessionData = useUserSettings()
  const { deliveryCountryCode } = useUserSettings()
  const { addToBasket } = sessionData

  const tryToAddToBasket = useRef<boolean>(false)
  const [selectedSibling, setSelectedSibling] = useState<number>(product.id)
  const [isSiblingSelectorExpanded, setSiblingSelectorExpanded] =
    useState(false)

  const hasStocks = product.stocks
    ? !selectedSize
      ? hasProductStocks(product.stocks)
      : hasDeclinationStocks(product.stocks, selectedSize)
    : false

  const isUnavailable = productIsUnavailable(
    product.stocks,
    product.alertAvailability
  )

  const handleAddToBasket = async () => {
    setNotification(null)
    if (!selectedSize) {
      setSizeSelectorExpanded(true)
      tryToAddToBasket.current = true
    } else {
      let newBasket
      try {
        newBasket = await addToBasket(selectedSize, locale)
        if ([Screen.xs, Screen.sm, Screen.md].includes(screen)) {
          setModalView(MODALS.ADD_TO_BASKET)
          openModal()
        } else {
          openTooltip(TOOLTIPS.BASKET)
        }
      } catch (err) {
        const errMsg =
          typeof err === 'string'
            ? t(`${'product:error.' + err}`)
            : t(`${'product:error.defaultMessage'}`)
        setModalView(MODALS.NOTIFICATION, {
          message: errMsg,
        })
        openModal()
      }
      if (newBasket) {
        tagEventAddToBasket({
          urlp: '/basket/add/page_product',
          locale,
          sessionData,
          productData: newBasket,
        })
        tagAlgoliaConvertion({
          deliveryCountryCode,
          from: FROM.DETAIL_PRODUCT,
          locale,
          objectId: product.reference,
          queryID: algoliaQueryId,
        })
      }
    }
  }

  const openPopinNotifyMe = (sku: string) => {
    setModalView(MODALS.NOTIFY_ME, {
      sku,
    })
    openModal()
  }

  const handleNotifyMe = () => {
    setNotification(null)
    if (!selectedSize) {
      setNotification({
        status: Status.Error,
        message: t('product:notifyModal.error_chooseSize'),
      })
      setSizeSelectorExpanded(true)
    } else {
      openPopinNotifyMe(selectedSize)
      tagAlgoliaConvertion({
        deliveryCountryCode,
        from: FROM.DETAIL_PRODUCT,
        locale,
        objectId: product.reference,
        queryID: algoliaQueryId,
        typeAction: ACTION.AVAILABILITY_ALERT,
      })
    }
  }

  const openPopinSize = () => {
    const props = {
      url: product.sizeGuide.url,
    }
    product.sizeGuide.type === SIZE_GUIDE.SHOES
      ? setModalView(MODALS.SHOES_SIZE_GUIDE, props)
      : setModalView(MODALS.SIZE_GUIDE, props)

    openModal()
  }

  useEffect(() => {
    if (!isSizeSelectorExpanded && tryToAddToBasket.current) {
      if (hasDeclinationStocks(product.stocks, selectedSize))
        handleAddToBasket()
      tryToAddToBasket.current = false
    }
  }, [isSizeSelectorExpanded])

  const IconComponent = useCallback(
    () => <Mail className={s.notifyMeIcon} />,
    []
  )

  const shouldDisplaySiblingsSelector =
    hasAttachColor || product?.siblings?.length > 0

  const siblings = [
    { id: product.id, attachColor: product.attachColor, color: product.color },
    ...(product?.siblings || []),
  ]

  const skuForTrustMulti = useMemo(() => {
    let defaultSkus =
      product?.declinations?.map((declination) => declination.sku) || []
    if (
      selectedSize &&
      product?.declinations?.some((d) => d.sku === selectedSize)
    ) {
      return [selectedSize]
    }
    return defaultSkus
  }, [product?.declinations, selectedSize])
  return (
    <>
      {enableTrustPilot && (
        <MediaQueries visible={['xs', 'sm', 'md']}>
          <TrustMultiSource sku={skuForTrustMulti} />
        </MediaQueries>
      )}
      {notification?.status && notification?.message && (
        <div className={s.alertContainer}>
          <Alert status={notification.status} message={notification.message} />
        </div>
      )}
      {(product?.declinations?.length > 0 || shouldDisplaySiblingsSelector) && (
        <div className={s.selectorsContainer}>
          {shouldDisplaySiblingsSelector && (
            <ProductSiblingSelector
              brand={product.brand}
              siblings={siblings}
              id={product.id}
              name={product.name}
              className={s.selector}
              expanded={isSiblingSelectorExpanded}
              onExpand={(value: boolean) => setSiblingSelectorExpanded(value)}
              selectedSibling={selectedSibling}
              onSiblingSelected={setSelectedSibling}
            />
          )}
          {product?.declinations?.length > 0 && (
            <ProductSizeSelector
              sizes={product.declinations}
              className={s.selector}
              expanded={isSizeSelectorExpanded}
              onExpand={(value: boolean) => setSizeSelectorExpanded(value)}
              onClickOutside={() => {
                tryToAddToBasket.current = false
              }}
              selectedSize={selectedSize}
              onSizeSelected={setSelectedSize}
              stocks={product.stocks}
            />
          )}
          {product?.sizeGuide && (
            <button className={s.productSizeGuide} onClick={openPopinSize}>
              {product?.sizeGuide?.type === SIZE_GUIDE.SHOES
                ? t('product:shoeSizeGuide')
                : t('product:sizeGuide')}
            </button>
          )}
        </div>
      )}
      {product.declinations?.length && selectedSize && (
        <ProductDeliveryInfo
          className={s.deliveryInfo}
          stocks={product.stocks}
          sku={
            product.declinations?.length === 1
              ? product?.declinations[0]?.sku
              : selectedSize
          }
        />
      )}
      <div className={s.actions}>
        {product?.stocks !== null ? (
          hasStocks ? (
            <Button className={s.action} onClick={handleAddToBasket}>
              {t('product:addToShoppingBag')}
            </Button>
          ) : isUnavailable ? (
            <Button className={cn(s.action, s.unavailable)}>
              {t('product:productIsUnavailable')}
            </Button>
          ) : (
            <>
              <Button
                className={s.notifyMeAction}
                icon={IconComponent}
                onClick={handleNotifyMe}
              >
                {t('product:notifyMe')}
              </Button>
            </>
          )
        ) : (
          <span className={s.loading}>
            <LoadingDots />
          </span>
        )}
      </div>

      <AddToListButtons
        product={product}
        selectedSize={selectedSize}
        setNotification={setNotification}
        className={s.listButtonsDesktop}
        disable={isUnavailable}
        algoliaQueryId={algoliaQueryId}
      />

      <div className={s.productActionsDetails}>
        <ProductPaymentsMethods
          className={s.paymentsMethods}
          amount={prices[lowestPrice]?.baseValue || prices[lowestPrice]?.value}
          euroAmount={prices[lowestPrice]?.euroValue}
        />

        {product?.greenablePictograms?.length ? (
          <ProductGreenable
            greenablePictograms={product.greenablePictograms}
            className={s.productGreenable}
          />
        ) : null}

        {product?.longDescription && (
          <ProductDescription
            description={product.longDescription}
            careIcons={product.care}
            className={s.productDescription}
          />
        )}
      </div>
      {enableTrustPilot && product?.declinations?.length && (
        <TrustReview declinations={product.declinations} />
      )}

      {prices && lowestPrice ? (
        <StickyBottomLine
          className={s.stickyBottomLine}
          hasStocks={hasStocks}
          price={selectedSize ? prices[selectedSize] : prices[lowestPrice]}
          labelFrom={!selectedSize && !singlePrice}
          onAddToBasket={handleAddToBasket}
          onOpenNotifyMeModal={handleNotifyMe}
          productUnavailable={isUnavailable}
        />
      ) : (
        <span className={s.loading}>
          <LoadingDots />
        </span>
      )}
    </>
  )
}

export default ProductActions
