import React, { FC, useState, useRef, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

import ProductGreenableMenu from './ProductGreenableMenu'
import ProductGreenableMenuLight from './ProductGreenableMenuLight'
import { AnimateMotion } from '@components/ui'

import s from './ProductGreenable.module.css'

export interface ProductGreenableProps {
  greenablePictograms: Array<{
    code: string
    name: string
    description: string
    path: string
  }>
  className?: string
}

const ProductGreenable: FC<ProductGreenableProps> = ({
  greenablePictograms,
  className,
}) => {
  const { t } = useTranslation()
  const [selectedPicto, setSelectedPicto] = useState<number>(0)

  const descriptionRef = useRef<HTMLDivElement>()
  const [descriptionFromHeight, setDescriptionFromHeight] =
    useState<string>('auto')
  const [descriptionToHeight, setDescriptionToHeight] = useState<string>('auto')

  useEffect(() => {
    setDescriptionFromHeight(`${descriptionToHeight}px`)
    setDescriptionToHeight(`${descriptionRef.current.offsetHeight}px`)
  }, [descriptionRef, selectedPicto])

  useEffect(() => {
    const updateDescriptionRef = () => {
      setDescriptionToHeight(`${descriptionRef.current.offsetHeight}px`)
    }

    window.addEventListener('resize', updateDescriptionRef)

    return () => window.removeEventListener('resize', updateDescriptionRef)
  }, [])

  return (
    <div className={className}>
      <h2 className={s.title}>
        {greenablePictograms.length > 1
          ? t('product:greenable.labelPlural')
          : t('product:greenable.label')}
      </h2>
      {greenablePictograms.length < 5 ? (
        <ProductGreenableMenuLight
          greenablePictograms={greenablePictograms}
          selectedPicto={selectedPicto}
          setSelectedPicto={setSelectedPicto}
        />
      ) : (
        <ProductGreenableMenu
          greenablePictograms={greenablePictograms}
          selectedPicto={selectedPicto}
          setSelectedPicto={setSelectedPicto}
        />
      )}
      <AnimateMotion
        initial={false}
        from={{ height: descriptionFromHeight }}
        to={{ height: descriptionToHeight }}
        className={s.animateMotion}
      >
        <div className={s.descriptionContainer} ref={descriptionRef}>
          <h2 className={s.name} key={`descriptionName-${selectedPicto}`}>
            {greenablePictograms[selectedPicto].name}
          </h2>
          <p className={s.description} key={`description-${selectedPicto}`}>
            {greenablePictograms[selectedPicto].description}
          </p>
        </div>
      </AnimateMotion>
    </div>
  )
}

export default ProductGreenable
