import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import cn from 'classnames'
import s from './PriceLine.module.css'

export interface PriceLineProps {
  className?: string
  price: DeclinationPrice
  labelFrom?: boolean
}

const PriceLine: FC<PriceLineProps> = ({ className, price, labelFrom }) => {
  const { t } = useTranslation()
  const { amount, baseAmount, discount } = price

  return (
    <div className={cn(s.container, className)}>
      {labelFrom && (
        <span className={s.labelFrom}>{t('product:price.from')}</span>
      )}
      <p className={cn(s.priceLine, 'notranslate')}>
        {baseAmount && baseAmount !== amount ? (
          <>
            <span className={s.price}>{baseAmount}</span>
            <span className={s.basePrice}>{amount}</span>
            <span className={s.discount}>{`-${discount}`}</span>
          </>
        ) : (
          <>
            <span className={s.price}>{amount}</span>
          </>
        )}
      </p>
    </div>
  )
}

export default PriceLine
