import React, { FC } from 'react'
import { useRouter } from 'next/router'
import useScreen, { Screen } from '@lib/hooks/useScreen'
import PriceLine from '@components/product/ProductDetails/PriceLine'
import AlmaBanner from '@components/common/AlmaBanner'
import s from './PriceBlock.module.css'

export interface PriceBlockProps {
  selectedSize: string | undefined
  prices: Prices
  lowestPrice?: string
  singlePrice: boolean
  className?: string
}

const PriceBlock: FC<PriceBlockProps> = ({
  selectedSize,
  prices,
  lowestPrice,
  singlePrice,
  className,
}) => {
  const { locale } = useRouter()
  const screen = useScreen()
  const price = selectedSize ? prices[selectedSize] : prices[lowestPrice]

  return (
    <div className={className}>
      <PriceLine price={price} labelFrom={!selectedSize && !singlePrice} />
      {![Screen.xs, Screen.sm].includes(screen) && (
        <AlmaBanner
          amount={price.baseValue || price.value}
          locale={locale}
          className={s.almaPlacement}
        />
      )}
    </div>
  )
}

export default PriceBlock
