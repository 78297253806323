import { FC, FormEvent, useEffect } from 'react'
import { NextSeo } from 'next-seo'
import { TagGeneric } from '@components/common/Eulerian'
import { TagPageCategory } from '@components/common/GoogleTagManager'

import { useRouter } from 'next/router'

import { Container } from '@components/ui'
import { PageContent } from '@components/common'
import { ProductsListingStructuredData } from '@components/common/StructuredData'

import { formatUrlNext } from '@utils/url/page'

import cn from 'classnames'
import s from './PageView.module.css'
import { BLOCK_TYPE } from '@components/blocks-cms/Blocks/Blocks'
import { MODALS, DiscountType, ENABLE_VP_MODAL } from '@constants'
import { useUI } from '@components/ui/context'
import { useUserSettings } from '@context/session'

interface Props {
  page?: Page
  currentPage?: number
  algoliaServerInfos?: AlgoliaServerInfos
}

interface Parameters {
  [key: string]: any
}

const PageView: FC<Props> = ({ page, currentPage, algoliaServerInfos }) => {
  const { _page } = useRouter().query
  const isPage1 = _page !== undefined && _page === '1'
  const isListingType = page.listingType || page.listingBlock

  const blockListing = page.blocks?.find(
    (block) => block.blockType === 'listing.products-listing'
  )

  const parameters = (() => {
    if (isListingType) {
      const listProductsIndex = page.blocks?.findIndex(
        (block) => block.type === BLOCK_TYPE.LIST_PRODUCTS
      )
      return (page.blocks?.[listProductsIndex]?.data as Parameters)?.parameters
    }
    return null
  })()

  const alternatesNext =
    page?.alternatesUrl?.map((alternate) => {
      return { ...alternate, href: formatUrlNext(alternate.href) }
    }) || []

  const currentPageParam = currentPage ? `?_page=${currentPage}` : ''
  const userSettings = useUserSettings()

  const { setModalView, openModal } = useUI()

  const openPrivateSalesModal = async () => {
    setModalView(MODALS.PRIVATE_SALES)
    openModal()
  }

  useEffect(() => {
    if (userSettings.logged) return

    const hasPrivateSale: boolean = userSettings.discountCampaigns?.some(
      (discount) => discount.type === DiscountType.Private
    )

    const now = Date.now()
    const popupDuration = 86400000 // One day in milliseconds
    const lastViewTimestamp: string | null = localStorage.getItem(
      '_private_sales_modal_timestamp'
    )
    const parsedTimestamp: number = parseInt(lastViewTimestamp)
    const isResetNeeded =
      !parsedTimestamp || now - parsedTimestamp >= popupDuration

    if (
      ENABLE_VP_MODAL &&
      hasPrivateSale &&
      (isResetNeeded || page?.customData?.showVPModal)
    ) {
      localStorage.setItem('_private_sales_modal_timestamp', String(now))
      openPrivateSalesModal()
    }
  }, [userSettings.logged])

  return (
    <>
      <NextSeo
        title={page.metaTitle}
        description={page.metaDescription}
        noindex={
          process.env.NEXT_PUBLIC_ENABLE_SEO !== 'enable' ||
          page.isIndexed !== true ||
          isPage1
        }
        nofollow={
          process.env.NEXT_PUBLIC_ENABLE_SEO !== 'enable' ||
          page.isIndexed !== true ||
          isPage1
        }
        canonical={formatUrlNext(page.canonicalUrl) + currentPageParam}
        languageAlternates={alternatesNext}
        openGraph={{
          type: 'website',
          title: page.metaTitle,
          description: page.metaDescription,
          url: page.canonicalUrl,
          images: [
            {
              url: `${process.env.NEXT_PUBLIC_ASSETS_URL}/bundles/smallablefront/css/img/logo.png`,
              width: 600,
              height: 315,
            },
          ],
        }}
        twitter={{
          cardType: 'summary',
          site: '@smallable',
        }}
      />
      {blockListing?.data && (
        <ProductsListingStructuredData
          blockListing={blockListing?.data}
          pageTitle={page?.title}
          pageDescription={page?.metaDescription}
        />
      )}
      <TagGeneric parameters={parameters} />
      <TagPageCategory
        section={parameters?.section}
        person={parameters?.person}
        sex={parameters?.sex}
        type={parameters?.type}
      />
      <Container
        className={cn(s.container, { [s.withTopSeparator]: !!page?.pageMenu })}
      >
        <PageContent
          algoliaServerInfos={algoliaServerInfos}
          blocks={page.blocks}
          menu={page.type !== 'inspiration' ? page.pageMenu : undefined}
        />
      </Container>
    </>
  )
}

export default PageView
